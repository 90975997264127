import '../polyfills';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { lighten } from 'polished';
import 'normalize.css/normalize.css';

import { ErrorBoundary } from '@/bugsnag'
import { UIStateProvider } from '@/components/ui-state-provider';
import themes, { colors } from '@/themes';
import { initializeTracking, trackPageView } from '@/tracker';
import Toast from '@/components/toast';
import DefaultHead from '@/components/default-head';
import Navbar from '@/components/navbar';
import Footer from '@/components/footer';
import Error from '@/pages/_error';
import { loadScript } from '@/utils';
import helpscoutHTML from '@/helpscout-html';

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  :root {
    background-color: ${props => props.theme.colors.background.primary};
    color: ${props => props.theme.colors.typography.primary};
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    max-width: 100vw;
    overflow-x: hidden;

    &.wf-active {
      font-family: Inter, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    }
  }

  a {
    color: ${colors.brand.primary};
    text-decoration: none;

    &:hover {
      color: ${lighten(.1, colors.brand.primary)};
      text-decoration: underline;
    }
  }

  .headroom {
    z-index: 100 !important;
  }

  blockquote {
    background-color: #FAFAFA;
    border-left: .125em solid #e7e7ef;
    color: ${props => props.theme.colors.typography.subtle};
    margin: 2em 0;
    padding: 1.5em;

    p {
      font-weight: 300;
      margin: 0;
    }
  }
`;

const getToastMessage = (code, alias) => {
  switch (code) {
    case 'user-not-found':
      return `${alias ? `The @${alias}` : 'The user'} account doesn't exist.`;
  }
};

function App ({ Component, pageProps }) {
  const router = useRouter();
  const [theme, setTheme] = useState('light');
  const [showToast, setShowToast] = useState(false);
  // const switchTheme = themeName => setTheme(themeName);

  const getToastProps = () => {
    const toastProps = {};

    if (router.query.error) {
      toastProps.type = 'error';
      toastProps.message = getToastMessage(router.query.error, router.query.alias);
    }

    if (router.query.funded && [true, 'true'].includes(router.query.funded)) {
      toastProps.type = 'success';
      toastProps.message = `Funding for @${router.query.alias} account processed. Balance update may take a few minutes.`;
    }

    return toastProps;
  };

  useEffect(() => {
    let timeout;

    if (typeof window !== 'undefined') {
      loadScript('https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js', () => {
          window.WebFont.load({
            google: {
              families: ['Inter:200,400,700,900&display=swap']
            }
          });
      });

      timeout = setTimeout(() => {
        const elementId = 'helpscout-script';
        if (!document.getElementById(elementId)) {
          const firstScriptTag = document.getElementsByTagName('script')[0];
          const tag = document.createElement('script');
          tag.innerHTML = helpscoutHTML;
          tag.defer = true;
          tag.id = elementId;

          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
      }, 15000);
    }

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    let timeout;

    if (showToast) {
      timeout = setTimeout(() => {
        setShowToast(false);
        router.replace('/');
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [showToast, router]);

  useEffect(() => {
    initializeTracking();
    const handleRouteChange = url => trackPageView(url);
    router.events.on('routeChangeComplete', handleRouteChange);

    if (router.query.error || router.query.funded) {
      setShowToast(true);
    }

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, router.query]);

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <ThemeProvider theme={themes.find(t => t.name === theme)}>
        <UIStateProvider>
          {showToast && <Toast {...getToastProps()} />}
          <DefaultHead />
          <GlobalStyles />
          <Navbar />
          <Component {...pageProps} />
          <Footer />
        </UIStateProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
