import styled, { css } from 'styled-components';

import { media } from '@/themes';

export default styled.div`
  background: ${props => props.theme.colors.background.card};
  border-radius: .25em;
  box-shadow: ${props => props.theme.shadows.card.default};
  padding: 1.5em;
  margin: 0 auto;
  text-transform: none;
  text-align: left;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-in-out;
  transition-duration: 125ms;
  white-space: normal;

  ${media.md`
    padding: 1em;
  `};

  ${({ clickable, theme }) => clickable && css`
    padding: 0;
    cursor: pointer;

    > a {
      color: ${theme.colors.typography.subtle};
      display: flex;
      height: 100%;
      padding: 1.4em;
      text-decoration: none;
      width: 100%;

      ${media.md`
        padding: 2em;
      `};
    }

    &:hover {
      box-shadow: ${props => props.theme.shadows.card.hover};
      color: ${theme.colors.typography.primary};
      text-decoration: none;
      transform: translateY(-.250em);
    }
  `};
`;
