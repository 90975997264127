import { useEffect } from 'react';
import { useUIContext } from '@/components/ui-state-provider';

export default function useNavbarBackground (color) {
  const { background, setBackground } = useUIContext();

  useEffect(() => {
    if (color) {
      setBackground(color);
    }

    return () => {
      setBackground('transparent');
    }
  }, []);

  return { background, setBackground };
}
