import React from 'react'
import Link from 'next/link';
import ErrorPage from 'next/error'
import styled from 'styled-components';
import { SadTear } from 'styled-icons/fa-regular';

import Bugsnag from '@/bugsnag';
import Section from '@/components/section';
import Title from '@/components/title';
import Button from '@/components/button';

const SadFaceIcon = styled(SadTear)`
  color: ${props => props.theme.colors.brand.primary};
  margin-bottom: 5em;
`;

export default class Page extends React.Component {
  static async getInitialProps (ctx) {
    if (ctx.err) {
      Bugsnag.notify(ctx.err);
    }
    return ErrorPage.getInitialProps(ctx);
  }

  render () {
    return (
      <Section centerContent>
        <Title>Oops! Something went wrong.</Title>
        <SadFaceIcon size={100} />
        <Link href="/" passHref>
          <a>
            <Button primary>Go back home</Button>
          </a>
        </Link>
      </Section>
    );
  }
}
