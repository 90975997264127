import styled, { css } from 'styled-components';

import { media } from '@/themes';
import MaxWidth from '@/components/max-width';

const AngleBottom = styled.svg`
  bottom: -1px;
  height: 12em;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;

  ${media.sm`
    height: 3em;
  `};

  ${media.md`
    height: 5em;
  `};
`;

const AngleTop = styled.svg`
  height: 12em;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  ${media.sm`
    height: 3em;
  `};

  ${media.md`
    height: 5em;
  `};
`;

const Container = styled.section`
  display: block;
  max-width: 100vw;
  padding: 5em 0;
  position: relative;
  width: 100%;

  @media print {
    padding: 3em 0;
  }

  ${media.sm`
    padding: 3em 0;
  `};

  ${({ angleTopReverse }) => angleTopReverse && css`
    ${AngleTop} {
      transform: rotateY(180deg);
    }
  `};
  ${({ angleBottomReverse }) => angleBottomReverse && css`
    ${AngleBottom} {
      transform: rotateY(180deg);
    }
  `};
`;

const Content = styled(MaxWidth)`
  position: relative;
  padding: 0 2em;
  z-index: 1;

  ${media.md`
    padding: 0 1.5em;
  `};

  ${media.sm`
    padding: 0 1em;
  `};

  ${({ centerContent }) => centerContent && css`
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  `};
`;

const Section = ({
  backgroundColor,
  centerContent,
  children,
  className,
  style,
  ...rest
}) => {
  const backgroundStyle = {
    background: backgroundColor,
    ...style
  };

  return (
    <Container
      {...rest}
      className={className}
      style={backgroundStyle}>
      {(rest.angleTop || rest.angleTopReverse) &&
        <AngleTop width="400" height="40" viewBox="0 0 400 40" preserveAspectRatio="none">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path" fill="white" points="0 0 400 0 400 40"></polygon>
          </g>
        </AngleTop>
      }
      <Content centerContent={centerContent}>{children}</Content>
      {(rest.angleBottom || rest.angleBottomReverse) &&
        <AngleBottom width="400" height="40" viewBox="0 0 400 40" preserveAspectRatio="none">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path-2" fill="white" points="0 0 0 40 400 40"></polygon>
          </g>
        </AngleBottom>
      }
    </Container>
  );
};

export default Section;
