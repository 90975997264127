import styled from 'styled-components';
import { Facebook, Linkedin, Twitter, Telegram, Angellist, Github, Medium } from 'styled-icons/fa-brands';

import { media } from '@/themes';

const Container = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;

  ${media.sm`
    justify-content: center;
  `};

  a {
    color: ${props => props.theme.colors.typography.verySubtle};
  }

  svg {
    height: 1.5em;
    width: 1.5em;
  }

  li + li {
    margin-left: 1em;
  }
`;

const SocialMediaProfiles = ({ facebook, linkedin, twitter, telegram, angelList, github, medium, className }) => (
  <Container className={className}>
    {!!facebook && <li><a href={facebook} rel="noopener" name="Bidali on Facebook"><Facebook /></a></li>}
    {!!linkedin && <li><a href={linkedin} rel="noopener" name="Bidali on LinkedIn"><Linkedin /></a></li>}
    {!!twitter && <li><a href={twitter} rel="noopener" name="Bidali on Twitter"><Twitter /></a></li>}
    {!!telegram && <li><a href={telegram} rel="noopener" name="Bidali on Telegram"><Telegram /></a></li>}
    {!!angelList && <li><a href={angelList} rel="noopener" name="Bidali on AngelList"><Angellist /></a></li>}
    {!!github && <li><a href={github} rel="noopener" name="Bidali on Github"><Github /></a></li>}
    {!!medium && <li><a href={medium} rel="noopener" name="Bidali on Medium"><Medium /></a></li>}
  </Container>
);

export default SocialMediaProfiles;
