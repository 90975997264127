import styled, { css } from 'styled-components';

export default styled.h2.attrs(props => ({
  maxSize: props.maxSize || '2.25em',
  minSize: props.minSize || '1.75em',
  vw: props.vw || 8
}))`
  font-size: ${props => `min(max(${props.minSize}, ${props.vw}vw), ${props.maxSize})`};

  ${({ rainbow, theme }) => rainbow && css`
    background-image: linear-gradient(45deg, ${theme.colors.brand.accent}, ${theme.colors.brand.primary});
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `};
`;
