import styled from 'styled-components';
import { Tablet, Gift } from 'styled-icons/feather';
import Link from 'next/link';
import { Flex, Box } from 'reflexbox/styled-components';

import config from '@/config';
import { colors, media } from '@/themes';
import useFooter from '@/hooks/use-footer';
import Section from '@/components/section';
import CTACard from '@/components/cta-card';
import Button from '@/components/button';
import SocialMediaProfiles from '@/components/social-media-profiles';
import NotFullColumn from '@/components/not-full-column';
import SubTitleBase from '@/components/sub-title';
import Paragraph from '@/components/paragraph';

const Container = styled.div`
  @media print {
    display: none !important;
  }
`;

const Newsletter = styled.div`
  background: #61618f;
  border-radius: 3em;
  margin-top: 5em;
  padding: 2em;
  text-align: center;
`;

const SubTitle = styled(SubTitleBase)`
  color: white;
`;

const FooterNav = styled(Flex)`
  border-top: 1px solid rgba(0, 0, 0, .05);
  color: #8282A1;
  margin-top: 5em;
  padding-top: 2em;

  ${media.sm`
    text-align: center;
  `};

  a {
    color: #8282A1;
    text-decoration: none;
  }

  h6 {
    color: ${props => props.theme.colors.brand.primary};
    font-size: 1em;
    font-weight: 300;
    margin: 1em 0;
    text-transform: uppercase;
  }
`;

const LinksList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    font-weight: 300;
    margin-bottom: .5em;
  }
`;

const Legals = styled.div`
  color: ${props => props.theme.colors.typography.verySubtle};

  & + & {
    margin-top: 1em;
  }
`;

const CTACardContainer = styled.div`
  margin: 0 1em;
  height: 100%;
`;

const Footer = () => {
  const { footer } = useFooter();
  const year = new Date().getFullYear();

  if (!footer) return null;

  return (
    <Container>
      <Section angleTopReverse backgroundColor={colors.background.primary}>
        <Flex flexWrap="wrap">
          <NotFullColumn>
            <Flex flexWrap="wrap" mx="-1em">
              <Box width={[1, 1, 1, 1/2]} mb={['1em', '1em', '1em', 0]}>
                <CTACardContainer>
                  <CTACard
                    icon={<Tablet />}
                    to="/"
                    title="Bidali App"
                    mainColor="#DD5184"
                    secondaryColor="#4B4DF1">
                    <Paragraph>Send money anywhere and earn instant cash back.</Paragraph>
                  </CTACard>
                </CTACardContainer>
              </Box>
              <Box width={[1, 1, 1, 1/2]}>
                <CTACardContainer>
                  <CTACard
                    icon={<Gift />}
                    to="https://spend.bidali.com/"
                    title="Buy Gift Cards"
                    mainColor="#59B2D3"
                    secondaryColor="#67E5A6">
                    <Paragraph>Buy gift cards from thousands of top brands around the globe.</Paragraph>
                  </CTACard>
                </CTACardContainer>
              </Box>
            </Flex>
            <Newsletter>
              <SubTitle maxSize="1.75em" minSize="1.5em" vw={5}>Stay up to date and subscribe to our not-too-frequent newsletter.</SubTitle>
              <Button accent size="large" gaLabel="newsletter_signup" to="https://cdn.forms-content.sg-form.com/de375558-cdc0-11e9-920e-ba3fbbb30976">Subscribe</Button>
            </Newsletter>
          </NotFullColumn>
        </Flex>
        <FooterNav flexWrap="wrap">
          <Box width={[1, 1/3]}>
            <SocialMediaProfiles
              angelList="https://angel.co/bidali"
              facebook="https://www.facebook.com/bidalihq"
              linkedin="https://www.linkedin.com/company/bidali"
              medium="https://medium.com/bidali"
              twitter="https://twitter.com/bidalihq" />
          </Box>
          <Box width={[1, 1/6]}>
            <h6>Products</h6>
            <LinksList>
              <li><Link href="https://spend.bidali.com" title="Buy gift cards and mobile phone air time and data plans from Nike, Amazon, Walmart, Uber, Vodaphone, Airtel, Verizon and more">Gift Card Store</Link></li>
              <li><Link href="/" title="Bidali mobile app">Cashback App</Link></li>
            </LinksList>
          </Box>
          <Box width={[1, 1/6]}>
            <h6>Company</h6>
            <LinksList>
              <li><Link href="/about/" title="Learn who is behind Bidali">About</Link></li>
              <li><Link href="/careers/" title="Careers in future finance at Bidali">Careers</Link></li>
              <li><Link href="/contact/" title="Contact information">Contact</Link></li>
              <li><Link href="/blog/" title="Latest news on the Bidali blog">Blog</Link></li>
            </LinksList>
          </Box>
          <Box width={[1, 1/6]}>
            <h6>Resources</h6>
            <LinksList>
              <li><Link href="https://support.bidali.com" title="Bidali help and support">Support</Link></li>
              <li><Link href="/branding/" title="Bidali logos and brand resources">Branding</Link></li>
              <li><Link href="/press/" title="Bidali press kit">Press</Link></li>
            </LinksList>
          </Box>
          <Box width={[1, 1/6]}>
            <h6>Policies</h6>
            <LinksList>
              <li><Link href="/policies/terms/" title="Bidali terms of service">Terms</Link></li>
              <li><Link href="/policies/privacy/" title="Bidali privacy policy">Privacy</Link></li>
              <li><Link href="/policies/security/" title="Bidali security policy">Security</Link></li>
              <li><Link href="/policies/cookies/" title="Bidali cookie policy">Cookies</Link></li>
              <li><Link href="/policies/aml/" title="Bidali AML and KYC policy">AML & KYC</Link></li>
            </LinksList>
          </Box>
        </FooterNav>
        <Legals>
          <small>&copy; Bidali Inc. {year}. Website Version: v{`${config.appVersion}`}</small>
        </Legals>
        <Legals>
          <small><sup>†</sup>Bidali Cash is a closed-loop, prepaid, stored-value instrument, similar to a digital e-gift card. Bidali Cash is issued solely by Bidali and represents pre-purchased credit at Bidali that is redeemable for goods and services offered on the Bidali Platform. Bidali Cash is denominated in official government issued currency (such as CAD or USD). Bidali Cash has no cash value, and is not redeemable for cash. Bidali Cash is issued as a cryptographically secured rewards token on a distributed ledger in order to improve fraud, loss, and theft prevention. Your Bidali Account Balance is not a deposit account which means that your Bidali Cash account balance will not be protected by the Canadian Deposit Insurance Corporation (&quot;CDIC&quot;) or Federal Deposit Insurance Corporation (&quot;FDIC&quot;) in the United States. By using the Bidali Services you acknowledge that the purchase of Bidali Cash is a commercial transaction whereby you are purchasing non-refundable stored value credit, akin to a gift card or voucher, for use on the Bidali platform. As such, your purchase of Bidali Cash should not be misconstrued for a bank deposit, loan, or purchase of a derivative or security. For more details please review our <Link href="/policies/terms"><a>Terms of Use</a></Link>.</small>
        </Legals>
        <Legals>
          <small>The merchant as well as its owners and affiliates (&quot;Brand&quot;), for whom gift cards, mobile airtime, and other products and services are available within the Bidali Services, are not sponsors of Bidali, the Bidali Services, or otherwise affiliated with Bidali other than through a reseller or affiliate marketing relationship. The logos and other identifying marks attached are trademarks of and owned by each represented merchant. For complete terms and conditions, please read the merchant&apos;s own gift card terms and conditions. Gift card trademarks and copyrights belong solely to the merchant.</small>
        </Legals>        
      </Section>
    </Container>
  );
};

export default Footer;
