import { useEffect } from 'react';
import { useUIContext } from '@/components/ui-state-provider';

export default function useFooter (isVisible) {
  const { footer, setFooter } = useUIContext();

  useEffect(() => {
    if (typeof isVisible === 'boolean') {
      setFooter(isVisible);
    }

    return () => setFooter(true);
  }, [isVisible]);

  return { footer, setFooter };
}
