import Head from 'next/head';
import config from '@/config';
// TODO: Pull this from the config
const SITE_ROOT = config.rootUrl;

const defaults = {
  shareImage: `${SITE_ROOT}/images/social/general-share-image-1200x628.png`,
  en: {
    title: 'Bidali | Discover a new way to pay and save',
    description: `Earn up to 10% cash back instantly on purchases. Send and receive money for free.`,
    author: 'Bidali HQ',
    keywords: 'payment bank digital decentralized blockchain e-commerce marketplace api subscription gift card reward cash back business paypal venmo blockchain cash local global amazon starbucks walmart playstation',
    lastBuilt: Date.now(),
    google: {
      name: 'Bidali',
      about: 'Bidali | Discover a new way to pay and save',
      description: `Earn up to 10% cash back instantly on purchases. Send and receive money for free.`,
      // image: 'google+_share_image_497x373.png',
      applicationCategory: 'Software',
      author: 'Bidali HQ'
    },
    facebook: {
      title: 'Bidali | Discover a new way to pay and save',
      description: `Earn up to 10% cash back instantly on purchases. Send and receive money for free.`,
      type: 'website',
      // image: 'facebook_share_image_1200x630.png',
      siteName: 'Bidali',
      updatedTime: (new Date()).toISOString(),
      admins: '517607527', // comma separated list
      page: '1748858885180222'
    },
    twitter: {
      handle: '@bidalihq',
      title: 'Bidali | Discover a new way to pay and save',
      description: `Earn up to 10% cash back instantly on purchases. Send and receive money for free.`,
      // image: 'twitter_card_image_800x418.png',
      creator: '@bidalihq'
    }
  }
};

const DefaultHead = () => (
  <Head>
    <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, user-scalable=0"/>
    <title key="title">{defaults.en.title}</title>
    <meta name="description" content={defaults.en.description} key="description" />
    <meta name="keywords" content={defaults.en.keywords} key="keywords" />
    <meta name="author" content={defaults.en.author} key="author" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content={defaults.en.twitter.handle} />
    <meta name="twitter:title" content={defaults.en.twitter.title} key="twittertitle" />
    <meta name="twitter:description" content={defaults.en.twitter.description} key="twitterdescription" />
    <meta name="twitter:creator" content={defaults.en.twitter.creator} />
    <meta name="twitter:image:src" content={defaults.shareImage} key="twitterimage" />

    <meta property="og:title" content={defaults.en.facebook.title} key="ogtitle" />
    <meta property="og:description" content={defaults.en.facebook.description} key="ogdescription" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={SITE_ROOT} key="ogurl" />
    <meta property="og:image" content={defaults.shareImage} key="ogimage" />
    <meta property="og:site_name" content={defaults.en.facebook.siteName} />
    <meta property="og:updated_time" content={defaults.en.facebook.updatedTime} />
    <meta property="og:rich_attachment" content="true" />
    <meta property="fb:admins" content={defaults.en.facebook.admins} />
    <meta property="fb:pages" content={defaults.en.facebook.page} />

    <meta itemProp="name" content={defaults.en.google.name} />
    <meta itemProp="about" content={defaults.en.google.about} key="itempropdescription" />
    <meta itemProp="description" content={defaults.en.google.description} key="itempropdescription" />
    <meta itemProp="author" content={defaults.en.google.author} />
    <meta itemProp="image" content={defaults.shareImage} key="itempropimage" />
    <meta itemProp="applicationCategory" content={defaults.en.google.applicationCategory} />
  </Head>
);

export default DefaultHead;
