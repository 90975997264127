import styled, { css } from 'styled-components';

export default styled.h1.attrs(props => ({
  maxSize: props.maxSize || '4em',
  minSize: props.minSize || '2.5em',
  vw: props.vw || 10
}))`
  font-size: ${props => `min(max(${props.minSize}, ${props.vw}vw), ${props.maxSize})`};
  font-weight: 900;
  margin-bottom: .5em;
  line-height: 1.125;

  ${({ rainbow, theme }) => rainbow && css`
    background-image: linear-gradient(45deg, ${theme.colors.brand.accent}, ${theme.colors.brand.primary});
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `};
`;
