import { createContext, useContext, useMemo, useState } from 'react';

export const Context = createContext({ backgroundColor: 'transparent', showFooter: true });
export const useUIContext = () => useContext(Context);
export const UIStateProvider = ({ backgroundColor = 'transparent', children, showFooter = true }) => {
  const [background, setBackground] = useState(backgroundColor);
  const [footer, setFooter] = useState(showFooter);

  const value = useMemo(() => {
    return {
      background,
      setBackground,
      footer,
      setFooter
    };
  }, [background, footer]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};
