import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import getConfig from 'next/config';

import config from '@/config';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

class DevErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError (error) {
    return { hasError: true };
  }

  componentDidCatch (error, errorInfo) {
    console.log(error, errorInfo);
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

let ErrBoundary = DevErrorBoundary;
const apiKey = serverRuntimeConfig.BUGSNAG_API_KEY || publicRuntimeConfig.BUGSNAG_API_KEY;
const appVersion = serverRuntimeConfig.APP_VERSION || publicRuntimeConfig.APP_VERSION;
const errorsToIgnore = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.'
];

let client = {
  notify: (e, ...rest) => {
    console.log('NOTIFY', e, rest);
  }
};

if (apiKey && process.env.CI !== 'true') {
  const options = {
    appVersion,
    releaseStage: config.bidaliCheckoutEnv,
    enabledReleaseStages: ['production', 'staging'],
    apiKey
  };

  Bugsnag.start({
    ...options,
    plugins: [new BugsnagPluginReact(React)],
    onError: event => {
      const err = event.errors[0];
      const msg = err.errorMessage;
      const shouldLog = !errorsToIgnore.includes(msg);
      return shouldLog;
    }
  });

  ErrBoundary = Bugsnag.getPlugin('react');
  client = Bugsnag;
}

export const ErrorBoundary = ErrBoundary;
export default client;
