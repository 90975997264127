import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { ArrowRight } from 'styled-icons/feather';

import { media } from '@/themes';
import Card from '@/components/card';

const Container = styled(Card)`
  height: 100%;

  > a {
    color: ${props => props.theme.colors.typography.subtle};

    &:hover {
      color: ${props => props.theme.colors.typography.primary};
    }
  }

  .title {
    margin-top: 0;
    margin-bottom: 0;

    svg {
      display: inline-block;
      margin-top: -0.2em;
      margin-left: 0.2em;
      vertical-align: middle;
    }
  }

  ${({ isMenu }) => isMenu && css`
    background: none;
    box-shadow: none;
    min-width: 25em;
    padding: .5em 1em;

    ${media.sm`
      min-width: calc(100vw - 2em);
    `};
  `};
`;

const Content = styled.div`
  cursor: ${props => !!props.onClick ? 'pointer' : 'default'};
  padding: 1.5em;

  ${({ isMenu }) => isMenu && css`
    padding: 0;
  `};

  ${media.sm`
    padding: 0;
  `};

  h5 {
    font-size: 1.25em;
    font-weight: 400;

    ${({ isMenu }) => isMenu && css`
      font-size: 1.125em;
      margin-bottom: 0;

      ${media.sm`
        font-size: 1em;
      `};
    `};
  }

  p {
    font-size: 1.125em;
    font-weight: 300;
    margin-bottom: 0;

    ${({ isMenu }) => isMenu && css`
      font-size: 1em;
      font-weight: 400;
      margin-top: .5em;

      ${media.sm`
        font-size: .85em;
      `};
    `};
  }
`;

const IconContainer = styled.div`
  float: left;
  border-radius: 50%;
  margin-right: 2em;
  padding: .75em;
  width: 3.25em;

  img, svg {
    color: white;
    display: block;
    stroke-width: 1.5;
    width: 100%;
  }
`;

const CTACard = ({ icon, mainColor, secondaryColor, title, to, children, onClick, isMenu }) => {
  const background = secondaryColor
    ? `linear-gradient(to bottom right, ${secondaryColor}, ${mainColor})`
    : mainColor;

  return (
    <Container clickable={!isMenu} isMenu={isMenu}>
      <Link href={to} alt={title} passHref>
        <Content isMenu={isMenu} onClick={onClick}>
          {!!icon &&
            <div>
              <IconContainer style={{ backgroundImage: background }}>
                {icon}
              </IconContainer>
            </div>
          }
          <div style={{ overflow: 'hidden' }}>
            <h5 className="title" style={{ color: mainColor }}>{title} <ArrowRight size={20} /></h5>
            {children}
          </div>
        </Content>
      </Link>
    </Container>
  );
};

CTACard.propTypes = {
  mainColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default CTACard;
