import { useEffect } from 'react';
import GA from 'react-ga';
import { useRouter } from 'next/router';

import config from '@/config';

const pixelOptions = {
  autoConfig: true,
  debug: config.facebookPixelLoggingEnabled
};

export const initializeTracking = () => {
  if (window && window.location.hostname !== 'localhost') {
    if (config.gaTrackingId) {
      GA.initialize(config.gaTrackingId, { titleCase: false });
      GA.plugin.require('ec');
    }

    {/* if (config.facebookPixelId) {
      FBPixel.init(config.facebookPixelId, {}, pixelOptions);
    } */}

    if (config.redditAdvertiserId) {
      rdt('init', config.redditAdvertiserId);
    }
  }
};

export const fbTrackEvent = (name, params) => {
  {/* if (config.facebookPixelId) {
    FBPixel.track(name, params);
  } */}
};

export const twitterTrackEvent = (name, params) => {
  if (window.twq) {
    twq('track', name, params);
  }
};

export const trackPageView = (page) => {
  if (window.ga && config.gaTrackingId) {
    GA.set({
      page
    });
    GA.pageview(page);
  } else {
    console.log('trackPageView', page);
  }
}

export const trackEvent = (params) => {
  if (config.gaTrackingId) {
    GA.event(params);
  } else {
    console.log('trackEvent', params);
  }
};

export const redditTrackEvent = (name) => {
  if (config.redditAdvertiserId) {
    rdt('track', name);
  } else {
  }
};

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GA.set({ page, ...options });
    GA.pageview(page);

    {/* if (config.facebookPixelId && !options.skipFBPixel) {
      FBPixel.pageView();
    } */}
  };

  const HOC = (props) => {
    const router = useRouter();

    useEffect(() => {
      if (router.pathname) {
        trackPage(router.pathname);
      }
    }, [router.pathname]);

    return (
      <WrappedComponent {...props} />
    );
  };

  return HOC;
};
