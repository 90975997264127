import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { trackEvent, fbTrackEvent } from '@/tracker';
import { media } from '@/themes';

const Container = styled.button`
  align-items: center;
  background-color: white;
  border-radius: 3em;
  border: none;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.25);
  color: ${props => props.theme.colors.brand.primary};
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 1;
  max-width: 20em;
  outline: none;
  padding: 1em 1.75em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  will-change: transform, box-shadow;

  &:hover {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .35);
    color: ${props => props.theme.colors.typography.primary};
    text-decoration: none;
    transform: translateY(-.0625em);
  }

  > svg {
    height: 1em;
    margin-right: .5em;
    vertical-align: text-bottom;
  }

  ${({ primary, theme }) => primary && css`
    background-color: ${theme.colors.brand.primary};
    color: white;

    &:hover {
      color: white;
    }
  `};

  ${({ accent, theme }) => accent && css`
    background-color: ${theme.colors.brand.accent};
    color: white;

    &:hover {
      color: white;
    }
  `};

  ${({ ghost, theme }) => ghost && css`
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    color: ${theme.colors.brand.primary};
    padding: 0;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  `};

  ${({ outline }) => outline && css`
    background-color: transparent;
    border: 1px solid currentColor;
    box-shadow: none;
    color: currentColor;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  `};

  ${({ small }) => small && css`
    border-radius: 1.5em;
    font-size: .75em;
    padding: 1em 1.5em;
  `};

  ${({ large }) => large && css`
    border-radius: 5em;
    font-size: 1.25em;
    padding: 1.25em 2em;

    ${media.sm`
      font-size: 1em;
      padding: 1.5em;
    `};
  `};

  ${({ disabled }) => disabled && css`
    opacity: .25;
    filter: grayscale(1);
  `};

  & + & {
    margin: 0.5em 0;

    ${media.md`
      margin: 0 0.5em;
    `};
  }
`;

const Button = ({ disabled, primary, accent, ghost, gaLabel, size, to, className, children, outline, onClick, style }) => {
  const router = useRouter();
  const handleClick = event => {
    const params = {
      category: 'button',
      action: 'clicked',
      label: gaLabel
    };

    trackEvent(params);
    fbTrackEvent(gaLabel, params);

    if (!!to && typeof to === 'string') {
      const target = (to.includes('bidali.com') || to.charAt(0) === '/')
        ? '_self'
        : '_blank';

      if (target === '_self') {
        router.push(to);
      } else {
        window.open(to, target);
      }
    } else {
      onClick(event);
    }
  };

  return (
    <Container
      accent={accent}
      className={className}
      disabled={disabled}
      ghost={ghost}
      onClick={handleClick}
      outline={outline}
      primary={primary}
      size={size}
      style={style}>
      {children}
    </Container>
  );
};


Button.defaultProps = {
  size: 'normal',
  gaLabel: '',
  onClick: () => {}
};

export default Button;
