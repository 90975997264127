import getConfig  from 'next/config';
import { valueOrNull, boolValue } from './utils';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const appVersion = serverRuntimeConfig.APP_VERSION || publicRuntimeConfig.APP_VERSION;

module.exports = {
  appVersion,
  rootUrl: process.env.NEXT_PUBLIC_ROOT_URL,
  assetsUrl: 'https://assets.bidali.com',
  gaTrackingId: valueOrNull(process.env.NEXT_PUBLIC_GA_TRACKING_ID),
  adwordsConversionId: valueOrNull(process.env.NEXT_PUBLIC_ADWORDS_CONVERSION_ID),
  adwordsConversionLabel: valueOrNull(process.env.NEXT_PUBLIC_ADWORDS_CONVERSION_LABEL),
  facebookPixelId: valueOrNull(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID),
  facebookPixelLoggingEnabled: boolValue(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_LOGGING_ENABLED),
  redditAdvertiserId: valueOrNull(process.env.NEXT_PUBLIC_REDDIT_ADVERTISER_ID),
  bugsnagApiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  inMaintenanceMode: boolValue(process.env.NEXT_PUBLIC_IN_MAINTENANCE_MODE),
  debugLogging: boolValue(process.env.NEXT_PUBLIC_DEBUG_LOGGING),
  disableAccountFunding: boolValue(process.env.NEXT_PUBLIC_DISABLE_ACCOUNT_FUNDING),
  supportUrl: 'https://support.bidali.com',
  walletAPIUrl: valueOrNull(process.env.NEXT_BIDALI_WALLET_API),
  bidaliPublicKey: valueOrNull(process.env.NEXT_PUBLIC_BIDALI_PUBLIC_KEY),
  bidaliCheckoutEnv: valueOrNull(process.env.NEXT_PUBLIC_BIDALI_CHECKOUT_ENV),
  bidaliCheckoutSDK: valueOrNull(process.env.NEXT_BIDALI_CHECKOUT_SDK),
  appUrlScheme: valueOrNull(process.env.NEXT_PUBLIC_SCHEME),
  appDownloadUrl: {
    beta: {
      ios: process.env.NEXT_PUBLIC_BETA_URL_IOS,
      android: process.env.NEXT_PUBLIC_BETA_URL_ANDROID
    },
    production: {
      ios: process.env.NEXT_PUBLIC_PRODUCTION_URL_IOS,
      android: process.env.NEXT_PUBLIC_PRODUCTION_URL_ANDROID
    }
  }
}
