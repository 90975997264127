import { css } from 'styled-components';

export const maxContentWidth = '1440px';

export const colors = {
  background: {
    card: '#FFFFFF',
    inverted: '#4C4C71',
    primary: '#FFFFFF'
  },
  brand: {
    accent: '#E4517F',
    primary: '#4B4DF1'
  },
  typography: {
    primary: '#4C4C71',
    subtle: '#8282A1',
    verySubtle: 'rgba(0, 0, 0, .30)',
    inverted: 'rgba(255, 255, 255, .9)',
    invertedSubtle: 'rgba(255, 255, 255, .5)'
  },
  status: {
    danger: '#EB3449',
    info: '#32C5FF',
    success: '#2DD8A4',
    warning: '#F2C94C'
  }
};

export const breakpoints = {
  xs: 320,
  sm: 615,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 3000
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export default [
  {
    name: 'light',
    maxContentWidth,
    colors,
    shadows: {
      primary: '0 4px 12px 0 rgba(74, 144, 226, .08), 0 1px 2px 0 rgba(0, 0, 0, .10)',
      hover: '0 .125em .25em rgba(0,0,0,.15),0 .5em 1.5em rgba(75,77,241,.15)',
      card: {
        default: 'rgba(0, 0, 0, .06) 0px 2px 4px 0px, rgba(0, 0, 0, 0.10) 0px 2px 4px 1px',
        hover: '0 .25em .5em rgba(0, 0, 0, .085), 0 .75em 1.25em rgba(0, 0, 0, .05)'
      }
    }
  },
  {
    name: 'dark',
    maxContentWidth,
    colors: {
      ...colors,
      background: {
        primary: '#121212',
        inverted: colors.background.primary,
        card: '#232323'
      },
      typography: {
        primary: 'rgba(255, 255, 255, .87)',
        secondary: '#F9F9F9',
        subtle: '#B2B3C4',
        inverted: '#0A0A0A',
        disabled: '#777'
      }
    },
    shadows: {
      primary: '0 4px 12px 0 rgba(74, 144, 226, .08), 0 1px 2px 0 rgba(255,255,255,0.10)',
      hover: '0 .25em .5em rgba(0, 0, 0, .085), 0 .75em 1.25em rgba(0, 0, 0, .05)',
      card: {
        default: 'none',
        hover: '0 .25em .5em rgba(0, 0, 0, .085), 0 .75em 1.25em rgba(0, 0, 0, .05)'
      }
    }
  }
];
