import { useState } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';
import Headroom from 'react-headroom';
import { Menu, X } from 'styled-icons/feather';

import { media } from '@/themes';
import useNavbarBackground from '@/hooks/use-navbar-background';
import MaxWidth from '@/components/max-width';

const NavbarItems = dynamic(() => import('@/components/navbar-items'), { ssr: false });

const Container = styled.nav`
  background: ${props => props.background};
  transition: background 500ms ease;

  .headroom--scrolled & {
    background: ${props => props.theme.colors.background.card};
    box-shadow: ${props => props.theme.shadows.primary};
  }
`;

const Content = styled(MaxWidth)`
  align-items: center;
  display: flex;
  padding: 1em;
`;

const Logo = styled.img`
  display: block;
  height: auto;
  width: 118px;
`;

const MenuToggler = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.colors.typography.primary};
  display: none;
  margin-left: auto;
  position: relative;
  transition: transform 125ms;

  svg {
    height: 1.5em;
    stroke-width: 2;
    width: 1.5em;
  }

  ${media.sm`
    display: inline-block;
  `};

  ${({ showMenu }) => showMenu && css`
    transform: translate(-.5em, .5em);
  `};
`;

const Navbar = () => {
  const { background } = useNavbarBackground();
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <Headroom wrapperStyle={{ marginBottom: -1 }}>
      <Container background={background}>
        <Content>
          <Link href="/">
            <a>
              <Logo
                height="28"
                src="https://assets.bidali.com/company/bidali/logo.png"
                width="118" />
            </a>
          </Link>
          <NavbarItems onToggle={handleMenuToggle} showMenu={showMenu} />
          <MenuToggler onClick={handleMenuToggle} showMenu={showMenu}>
            {showMenu ? <X /> : <Menu />}
          </MenuToggler>
        </Content>
      </Container>
    </Headroom>
  );
};

export default Navbar;
