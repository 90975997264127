import styled, { css } from 'styled-components';
import { AlertOctagon, CheckCircle } from 'styled-icons/feather';

import Paragraph from '@/components/paragraph';

const Container = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.background.card};
  border-radius: 1em;
  box-shadow: ${props => props.theme.shadows.hover};
  display: flex;
  left: 50%;
  padding: 1em;
  pointer-events: none;
  position: fixed;
  top: 2em;
  transform: translateX(-50%);
  z-index: 1000;

  ${Paragraph} {
    margin: 0 0 0 .5em;
  }

  ${({ theme, type }) => type === 'error' && css`
    svg {
      color: ${theme.colors.status.danger};
    }
  `};

  ${({ theme, type }) => type === 'success' && css`
    svg {
      color: ${theme.colors.status.success};
    }
  `};
`;

const Toast = ({ message, type }) => {
  return (
    <Container type={type}>
      {type === 'error' && <AlertOctagon size={22} strokeWidth={2} />}
      {type === 'success' && <CheckCircle size={22} strokeWidth={2} />}
      <Paragraph>{message}</Paragraph>
    </Container>
  );
};

export default Toast;
